import React, { useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { Formik } from "formik"

import { initialValues, validationSchema } from "../components/hgv-pack"
import { submitForm, handleSubmitResponse, FormatFormData, dateCheck, dateOfBirthCheck } from "../services"

import { LCard, Col2 } from "../style/containers"
import Layout from "../components/layout"
import SButton from "../components/button"
import { Documents } from "../components/fileUpload"
import Signature from "../components/Signature"
import Form from "../components/form/FormHelper"
import { FormikTabs } from "../components/form/tabs"
import { FormikText } from "../components/form/textarea"
import {
  FormikInput,
  Error,
  FormikSelect,
  FormikCheckbox,
  Label,
  FormikDatepicker,
} from "../components/form"
import { TagSelector } from "../components/form/tags"

import { Education } from "../components/IndustrialApplication"
import EmploymentHistory from "../components/EmploymentHistory"
import HealthAndMedicalQuestionnaire from "../components/HealthAndMedicalQuestionnaire"

import Answer1 from "../assets/images/hgv/Driving.png"
import Answer2 from "../assets/images/hgv/Available.png"
import Answer3 from "../assets/images/hgv/stop-sign.jpg"
import Answer4 from "../assets/images/hgv/Break.png"
import Answer5 from "../assets/images/hgv/overhead-cable-image.jpg"
import TeamLiftingImg from "../assets/images/lifting-dos-and-donts.jpg"
import References from "../components/References"
import { countryList } from "../constants"

const HealthcareApplication = () => {
  const sigCanvas = useRef({})
  const [signatureDrawn, setSignatureDrawn] = useState()
  const [errorText, setErrorText] = useState([])

  const onSubmit = async (values, actions) => {
    if (signatureDrawn) {
      const formatData = {
        ...values,
        date_of_birth:
          dateCheck(values.date_of_birth),
        passport_expiry: dateCheck(values.passport_expiry),
        when_can_you_start: dateCheck(values.when_can_you_start),
        security_clearance_date_start: dateCheck(values.security_clearance_date_start),
        security_clearance_date_end: dateCheck(values.security_clearance_date_end),
        education: {
          ...values.education,
          start_date: dateCheck(values.education?.start_date),
          end_date: dateCheck(values.education?.end_date),
        },
        employment_history: values.employment_history.map(emp => {
          return {
            ...emp,
            date_from: dateCheck(emp.date_from),
            date_to: dateCheck(emp.date_to)
          }
        })
      }
      const formData = await FormatFormData(formatData, sigCanvas)
      let res = await submitForm(formData, "/hgv")
      handleSubmitResponse(res, setErrorText)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>HGV Pack</title>
      </Helmet>
      <LCard>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            setFieldValue,
            values,
            isSubmitting,
            setFieldTouched,
            validateForm,
            errors,
          }) => (
            <Form>
              <h1>HGV Pack</h1>
              <Col2>
                <FormikInput
                  name="consultant_name"
                  type="text"
                  label="Consultant name"
                />
              </Col2>
              <h2>Personal information</h2>
              <Col2>
                <FormikInput name="first_name" type="text" label="First name" />
                <FormikInput name="surname" type="text" label="Surname" />
                <FormikDatepicker
                  name="date_of_birth"
                  placeholderText="Select a date"
                  label="Date of birth"
                  maxDate={dateOfBirthCheck()}
                />
                <FormikSelect
                  label="Title"
                  name="title"
                  options={[
                    { value: "Mr", label: "Mr" },
                    { value: "Mrs", label: "Mrs" },
                    { value: "Miss", label: "Miss" },
                    { value: "Ms", label: "Ms" },
                  ]}
                />
                <FormikInput
                  type="text"
                  label="Address line 1 - house number and street name"
                  name="address_1"
                />
                <FormikInput
                  type="text"
                  label="Address line 2"
                  name="address_2"
                />
                <FormikInput
                  type="text"
                  label="Town/City"
                  name="address_town"
                />
                <FormikInput type="text" label="County" name="address_3" />
                <FormikInput name="postcode" type="text" label="Postcode" />
                <FormikInput type="text" label="Country" name="country" />
                <FormikInput name="email" type="text" label="Email" />
                <FormikInput name="mobile" type="text" label="Mobile" />
                <FormikInput name="landline" type="text" label="Landline" />
                <FormikDatepicker
                  name="when_can_you_start"
                  placeholderText="Select a date"
                  label="When can you start work?"
                />
                {/* <TabsWithLabel
                  setFieldValue={setFieldValue}
                  name="can_you_work"
                  label="Can you work?"
                  tabs={[
                    { name: "days", label: "Days" },
                    { name: "nights", label: "Nights" },
                    { name: "shift weekends", label: "Shift Weekends" },
                  ]}
                /> */}
                <FormikInput
                  name="can_you_work"
                  type="text"
                  label="Can you work days, nights and/or shift weekends?"
                />
                <FormikInput
                  name="national_insurance"
                  type="text"
                  label="National Insurance Number"
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="has_full_driving_licence"
                  label="Do you have a full UK driving licence?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                <TagSelector
                  label="What are your current methods of transport?"
                  name="method_of_transport"
                  options={["Bike", "Bus", "Car", "Walk", "Other"]}
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="own_transport"
                  label="Do you have your own transport?"
                  tabs={[
                    { name: "yes", label: "Yes" },
                    { name: "no", label: "No" },
                  ]}
                />
                <FormikInput
                  name="right_to_work_type"
                  type="text"
                  label="Right to work number"
                />
                <FormikInput
                  name="right_to_work_number"
                  type="text"
                  label="Right to work type"
                />
              </Col2>
              <Documents />
              <h2>Emergency contact</h2>
              <Col2>
                <FormikInput name="emergency_name" type="text" label="Name" />
                <FormikInput
                  name="emergency_tel"
                  type="text"
                  label="Contact number"
                />
                <FormikInput
                  name="emergency_relation"
                  type="text"
                  label="Relationship"
                />
              </Col2>
              <hr />
              <Col2>
                <FormikSelect
                  label="Nationality"
                  name="nationality"
                  options={countryList}
                />
              </Col2>
              <Col2>
                <FormikInput
                  name="date_arrived"
                  type="text"
                  label="UK Arrival Date"
                  disabled={values?.nationality === "United Kingdom" || values?.nationality === ""}
                />
                <FormikInput
                  name="share_code"
                  type="text"
                  label="Share Code"
                  disabled={values?.nationality === "United Kingdom" || values?.nationality === ""}
                />
                <FormikInput
                  name="passport_number"
                  type="text"
                  label="Passport Number"
                />
                <FormikInput
                  name="passport_issue_date"
                  type="text"
                  label="Passport Issued"
                />
                <FormikInput
                  name="passport_expiry_date"
                  type="text"
                  label="Passport Expiry"
                />
              </Col2>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="medical_conditions"
                label="Do you have any medical condition?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
              />
              {values.medical_conditions === "Yes" ? (
                <FormikText
                  name="medical_conditions_details"
                  label="Please provide details"
                />
              ) : null}
              <h2>Bank details</h2>
              <Col2>
                <FormikInput name="bank_name" type="text" label="Bank name" />
                <FormikInput name="sort_code" type="text" label="Sort code" />
                <FormikInput
                  name="account_number"
                  type="text"
                  label="Account number"
                />
              </Col2>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="entitled_work_uk"
                label="Are you entitled by law to work in the UK?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
              />
              {values.entitled_work_uk === "No" ? (
                <FormikText
                  name="entitled_work_uk_details"
                  label="If NO - please indicate whether you require a work permit or a registration certificate under the workers regulation scheme (new EU Countries)?"
                />
              ) : null}
              <p>
                (All successful candidates will be required to supply proof of
                this offer employment. This complies with section 8 of the
                asylum and immigration Act 1966)
              </p>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="medical_conditions_requirements"
                label="Do you have any physical or mental health conditions, which would require special arrangements for your interview appointment or adjustments to the job you are applying for?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
              />
              <h2>Education</h2>
              <Education values={values} setFieldValue={setFieldValue} />
              <h2>Employment History</h2>
              <EmploymentHistory
                values={values}
                validateForm={validateForm}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                initialValues={initialValues}
              />
              <h2>Reference</h2>
              <References
                values={values}
                validateForm={validateForm}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                initialValues={initialValues}
              />
              <hr />
              <h2>Health and Medical</h2>
              <HealthAndMedicalQuestionnaire
                values={values}
                setFieldValue={setFieldValue}
              />
              <h2>Data Privacy Notice</h2>
              <p>
                Pin Point Recruitment process your personal information: a) to
                provide you with job placement services, b) for staff
                administration purposes, c) To maintain its contract or business
                relationship with you and d) for accounts and records. E) to
                assess your suitability for a position or task, f) for personal
                improvement, selection and appraisal purposes, and to improve
                our quality and performance of the services that we provide; and
                g)for the management and defence of legal claims and actions,
                compliance with court orders and other court orders and other
                legal obligations or with your consent. We may disclose your
                personal information to our clients, other Pin Point Recruitment
                branches, subcontractors who perform services on our behalf we
                would be required to do so, such as by a court order.
              </p>
              <h2>Medical Questionnaire</h2>
              <p>
                <strong>
                  Are you currently taking any of the following medication
                </strong>
              </p>
              <Col2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="clonazepam"
                  label="Clonazepam?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="diazepam"
                  label="Diazepam?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="flunitrazepam"
                  label="Flunitrazepam?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="lorazepam"
                  label="Lorazepam?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="morphine_opiate"
                  label="Morphine or Opiate and Opioid drugs e.g codine, Tramadol or
                Fentanyl?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="methadone"
                  label="Methadone?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="oxzaepam"
                  label="Oxzaepam?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="temazepam"
                  label="Temazepam?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
              </Col2>
              <p>
                <strong>Please note:</strong>
              </p>
              <p>You can drive after taking these drugs if:</p>
              <p>
                You have been prescribed then and followed advice on how to take
                them by a healthcare professional.
              </p>
              <p>
                They aren't causing you to be unfit to drive even if you're
                above the specified limits
              </p>
              <h2>Criminal convictions</h2>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="criminal_convictions"
                label="Have you ever been convicted of a criminal offence, other than a spent conviction under the
                Rehabilitation of Offenders Act 1974?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
              />
              <h2>Privacy Notice</h2>
              <section>
                <h3>Introduction</h3>
                <p>
                  We at Pin Point Recruitment are committed to protecting the
                  privacy of our candidates, clients and users of our website.
                  When you use Pin Point Recruitment you trust us with your
                  information and we are committed to preserving that trust and
                  providing a safe and secure user experience. We will ensure
                  that the information you submit to us via our website or
                  through any of our offices is only used for the purposes set
                  out in this Data Privacy Notice.
                </p>
                <p>
                  The General Data Protection Regulation (GDPR) (Regulation (EU)
                  2016/679) replaces the Data Protection Regulation (Directive
                  95/46/EC) from 25 May 2018. The Regulation aims to harmonise
                  data protection legislation across EU member states, enhancing
                  privacy rights for individuals and providing a strict
                  framework within which commercial organisations can legally
                  operate. Even though the UK has expressed its intention to
                  leave the EU in March 2019, the GDPR will be applicable in the
                  UK from 25th May 2018.
                </p>
                <p>
                  Your new rights under the GDPR are set out in this notice but
                  will only apply once the GDPR becomes law on 25th May 2018.
                </p>
                <p>
                  Please read the following carefully to understand our views
                  and practices regarding your personal data and how we will
                  treat it.
                </p>
                <p>Who controls your personal data?</p>
                <ul style={{ listStyle: "bullet" }}>
                  <li>
                    The Data Controller is Pin Point Recruitment a company
                    registered in the UK: Company Number 07601096
                  </li>
                  <li>
                    Address: Axwell House, Waterside Drive, Dunston, Gateshead,
                    NE11 9HU
                  </li>
                  <li>
                    The Data Controller's data protection representative is the
                    Head Of IT.
                  </li>
                  <li>You can contact them at IT@pin-point.co.uk</li>
                  <li>You can call them on 0191 261 5205</li>
                  <li>
                    Pin Point Healthcare is registered as a Data Controller with
                    the Information Commissioner's Office Certificate Number
                    Z7088630
                  </li>
                  <li>
                    Any reference to our Group means our subsidiaries, our
                    ultimate holding company and its subsidiaries, our
                    associated companies as defined in section 1159 of the UK
                    Companies Act 2006 (our Group)
                  </li>
                </ul>
                <p>
                  <strong>
                    Who we are, what we do and how we get your data
                  </strong>
                </p>
                <p>
                  Pin Point is a recruitment agency and recruitment business as
                  defined in the Employment Agencies Act 1973 (our business). We
                  also provide training and consultancy services from time to
                  time. We collect the personal data from the following types of
                  people to allow us to undertake our business;
                </p>
                <ul style={{ listStyle: "bullet" }}>
                  <li>
                    prospective and placed candidates for permanent or temporary
                    roles;
                  </li>
                  <li>
                    prospective and live client contacts (including referee
                    contacts provided by candidates);
                  </li>
                  <li>supplier contacts to support our services;</li>
                  <li>
                    employees, consultants, temporary workers and contractors;
                  </li>
                </ul>
                <p>
                  You may have applied directly to us or we may have found your
                  details from a jobs board or social networking site. We are
                  able to process your data if we have a legal basis for doing
                  so. There are six legal bases for processing data but we will
                  rely on (1) your consent to send direct marketing messages
                  about services other than our recruitment services, (2) that
                  the processing is necessary for the performance of a contract
                  with you, or (3) that we have a legitimate interest in
                  processing your personal data.
                </p>
                <p>
                  We collect information about you to carry out our core
                  business and ancillary activities.
                </p>
              </section>
              <section>
                <h3>Section 1:</h3>
                <p>
                  <strong>The data we collect and how we use it</strong>
                </p>
                <p>
                  This section applies to individuals wishing to use or using
                  our Recruitment Services or looking for a role to work with us
                  Ca candidate'):
                </p>
                <p>
                  <strong>
                    The personal data we collect or receive includes the
                    following as applicable:
                  </strong>
                </p>
                <ul style={{ listStyle: "bullet" }}>
                  <Col2>
                    <li>name</li> <li>address</li>
                    <li>email and other contact details</li>
                    <li>date of birth</li>
                    <li>
                      job history (including information relating to placements
                      through us)
                    </li>
                    <li>educational history, qualifications &amp; skills</li>
                    <li>
                      visa and other right to work or identity information
                    </li>
                    <li>passport/Driving licence</li> <li>bank details</li>
                    <li>national insurance and tax (payroll) information</li>
                    <li>next of kin and family details</li>
                    <li>contact details of referees</li>
                    <li>
                      personal information relating to hobbies, interests and
                      pastimes
                    </li>
                    <li>
                      information contained in references and pre-employment
                      checks from third parties
                    </li>
                    <li>
                      other sensitive personal information such as health
                      records (see 'sensitive personal data' section below)
                    </li>
                    <li>your marketing preferences</li>
                  </Col2>
                </ul>
                <p>
                  <strong>
                    We may obtain your personal data from the following sources
                    (please note that this list is not exhaustive):
                  </strong>
                </p>
                <ul style={{ listStyle: "bullet" }}>
                  <li>
                    you (e.g. a curriculum vitae, application or registration
                    form)
                  </li>
                  <li>a client</li>
                  <li>other candidates</li>
                  <li>online jobsites</li>
                  <li>marketing databases</li>
                  <li>the public domain</li>
                  <li>social media such as Linkedln</li>
                  <li>at interview</li>
                  <li>
                    conversations on the telephone or video conferencing (which
                    may be recorded)
                  </li>
                  <li>notes following a conversation or meeting</li>
                  <li>our websites and software applications</li>
                </ul>
                <p>
                  Where you are a Candidate and we have obtained your personal
                  data from a third party such as an online job board, it is our
                  policy to advise you of the source when we first communicate
                  with you.
                </p>
                <p>
                  <strong>How we will use your personal data:</strong>
                </p>
                <p>The processing of your personal information may include:</p>
                <ul style={{ listStyle: "bullet" }}>
                  <li>
                    collecting and storing your personal data, whether in manual
                    or electronic files
                  </li>
                  <li>notifying you of potential roles or opportunities</li>
                  <li>
                    assessing and reviewing your suitability for job roles
                  </li>
                  <li>
                    introducing and/or supplying you to actual or potential
                    clients
                  </li>
                  <li>
                    liaising with awarding organisations and funding providers
                  </li>
                  <li>CQC related enquiries and auditing requirements</li>
                  <li>
                    engaging you for a role with us or with our clients,
                    including any related administration e.g. timesheets and
                    payroll
                  </li>
                  <li>
                    collating market or sector specific information and
                    providing the same to our clients
                  </li>
                  <li>
                    sending information to third parties with whom we have or
                    intend to enter into arrangements which are related to our
                    recruitment services
                  </li>
                  <li>
                    providing information to regulatory authorities or statutory
                    bodies, and our legal or other professional advisers
                    including insurers
                  </li>
                  <li>to market our recruitment services</li>
                  <li>retaining a record of our dealings</li>
                  <li>
                    establishing quality, training and compliance with our
                    obligations and best practice
                  </li>
                  <li>
                    for the purposes of backing up information on our computer
                    systems
                  </li>
                </ul>
                <p>
                  <strong>
                    Why we process your personal data and our legal
                    justification for doing so:
                  </strong>
                </p>
                <p>
                  Our legal basis for the processing of personal data is our
                  legitimate business interests, described in more detail below,
                  although we will also rely on contract, legal obligation and
                  consent for specific uses of data.
                </p>
                <p>
                  We will rely on contract if we are negotiating or have entered
                  into a placement agreement with you or your organisation or
                  any other contract to provide services to you or receive
                  services from you or your organisation.
                </p>
                <p>
                  We will rely on legal obligation if we are legally required to
                  hold information on to you to fulfil our legal obligations.
                </p>
                <p>
                  We will in some circumstances rely on consent for particular
                  uses of your data and you will be asked for your express
                  consent, if legally required. Examples of when consent may be
                  the lawful basis for processing include permission to
                  introduce you to a client (if you are a candidate).
                </p>
                <p>
                  On the 25th May 2018, Pin Point Recruitment are compliant with
                  the General Data Protection Regulation (GDPR). You can find
                  our full policies and procedures on our website at{" "}
                  <a href="http://www.pjnpoint.co.uk" target="_blank">
                    http://www.pjnpoint.co.uk
                  </a>{" "}
                  OR{" "}
                  <a href="http://www.pinpoint-traininq.net" target="_blank">
                    http://www.pinpoint-traininq.net
                  </a>
                  .
                </p>
                <p>
                  By signing at the end of tis form, you agree to the terms and
                  conditions of this regulation.
                </p>
              </section>
              <section>
                <h2>Consent Form</h2>
                <p>
                  I{" "}
                  <strong>
                    {values.first_name && values.surname
                      ? values.first_name + " " + values.surname
                      : "___________"}
                  </strong>{" "}
                  confirm that I give Pin Point permission to pass my contact
                  information on to clients I am working for, should they
                  require it whilst I am on assignment with them.
                  <br />I understand that clients will require copies of my
                  driving Iicence, digital tachograph card and Drivers CPC card.
                  I am happy for this to be provided along with my national
                  insurance number, to facilitate the licence checking process.
                  If the address on my photo licence card does not match the
                  address on my registration pack I am aware this may also need
                  to be provided to the client. In addition to the above clients
                  ask us to provide the licence check that we have already run
                  as part of your registration.
                </p>
                <p>
                  The above information will only be passed to clients in order
                  to help you secure work and will only be sent to clients once
                  the offer of work has been accepted by yourself.
                </p>
              </section>
              <section>
                <h2>New Drivers Declaration</h2>
                <p>
                  <strong>
                    This declaration is to be signed when any new driver
                    registers with Pin Point Recruitment.
                  </strong>
                </p>
                <p>
                  This is a declaration that I{" "}
                  <strong>
                    {values.first_name && values.surname
                      ? values.first_name + " " + values.surname
                      : "___________"}{" "}
                  </strong>
                  understand and will comply with all relevant legislation to
                  the road transport and any other reasonable request whilst
                  working on any assignments for Pin Point Recruitment.
                </p>
                <p>In particular, I declare that I;</p>
                <ol>
                  <li>
                    Understand and comply with the rules on driving hours and
                    keeping the correct records of my driving time, and working
                    time.
                  </li>
                  <li>
                    Will ensure I have the correct daily rest periods and weekly
                    rest periods before commencing any assignments.
                  </li>
                  <li>
                    Will ensure I have enough working and driving time available
                    before accepting any assignments.
                  </li>
                  <li>
                    When on assignments I will have all relevant driving time
                    records on my person, namely digital tachograph card or
                    analogue tachographs and any print outs required.
                  </li>
                  <li>
                    Will ensure alt analogue tachographs are returned to the
                    clients after 28 days.
                  </li>
                  <li>
                    Will download my digital tachograph card at the start and
                    end of each shift/ assignment I carry out for Pin Point
                    Recruitment.
                  </li>
                  <li>
                    Understand and comply with rules on working time limits for
                    mobile workers in the road transport sector.
                  </li>
                  <li>
                    Will inform Pin Point Recruitment of any changes to penalty
                    points incurred or changes to my driving licence.
                  </li>
                  <li>
                    Will ensure I apply to renew my driving licence, digital
                    tachograph card and CPC card within a timely manner. will
                    keep Pin Point updated with any hold ups regarding the
                    renewals.
                  </li>
                  <li>
                    Understand and comply with speed limits of roads and
                    vehicles.
                  </li>
                  <li>
                    Will not drive a vehicle whilst under the influence of drink
                    or drugs (whether illegal or prescribed).
                  </li>
                  <li>
                    Ensure the load on my vehicle is secure and within the
                    weight limits of the vehicle in terms of weight.
                  </li>
                  <li>
                    Will comply with any health and safety requirements required
                    by any assignments.
                  </li>
                  <li>
                    Will return the vehicle and its equipment in the same
                    condition I received it in.
                  </li>
                  <li>
                    Will report accidents firstly to the police/emergencies
                    services if required, then to Pin Point Recruitment and
                    lastly to the client who the assignment is for.
                  </li>
                  <li>
                    Will accept responsibility for any fines which relate to
                    driving otherwise within accordance to my licence or the
                    road traffic laws, ie no seat belt, speeding etc.
                  </li>
                </ol>
                <p>
                  I declare that I am legally able to undertake the duties
                  required of me and will advise Pin Point if I have any doubts,
                  concerns or problems with any issue in relation to performing
                  my duties during my placement.
                </p>
              </section>
              <section>
                <h2>Safety Information</h2>
                <p>
                  This information sheet contains some basic rules for safe
                  lifting that followed will protect you from injury and harm.
                  Each year most common industrial injury caused by lifting.
                  Follow the rules and always remember, what ever the deadline,
                  put your own safety first!
                </p>
                <ol>
                  <li>
                    Never lift more than you can manage. If you are struggling,
                    stop immediately!
                  </li>
                  <li>Check for nails, splinters or sharp objects.</li>
                  <li>
                    follow these simple rules:
                    <ol style={{ listStyle: "lower-alpha" }}>
                      <li>Keep your back straight, feet apart.</li>
                      <li>Look straight ahead</li>
                      <li>Bend your knees</li>
                      <li>Lift!</li>
                    </ol>
                  </li>
                </ol>
                <img src={TeamLiftingImg} alt="Team lifting Guide" />
                <h3>Team lifting</h3>
                <ol>
                  <li>
                    Make sure you have a clear view of where you are going.
                  </li>
                  <li>Communicate and listen for instructions.</li>
                  <li>Follow the lifting rules.</li>
                  <li>If you are struggling. Speak up.</li>
                  <li>Make sure that you lift and lower at the same time!</li>
                </ol>
                <h3>
                  Remember the rules, safety first. You only get one chance!
                </h3>
              </section>
              <section>
                <h3>Transport &amp; Logistics</h3>
                <Col2>
                  <FormikCheckbox name="class_1" label="Class 1" />
                  <FormikCheckbox name="class_2" label="Class 2" />
                  <FormikCheckbox name="ampi_roll" label="Ampi-Roll" />
                  <FormikCheckbox name="box_vans" label="Box Vans" />
                  <FormikCheckbox
                    name="building_suppliers"
                    label="Building Supplies"
                  />
                  <FormikCheckbox name="chains" label="Chains" />
                  <FormikCheckbox
                    name="container_trailers"
                    label="Container Trailers"
                  />
                  <FormikCheckbox name="continental" label="Continental" />
                  <FormikCheckbox name="cps_holder" label="CPS Holder" />
                  <FormikCheckbox
                    name="curtain_slider"
                    label="Curtain Slider"
                  />
                  <FormikCheckbox name="draw_bar" label="Draw Bar" />
                  <FormikCheckbox name="flat_bed" label="Flat Bed" />
                  <FormikCheckbox
                    name="rope_and_sheet"
                    label="Rope &amp; Sheet"
                  />
                  <FormikCheckbox name="glass_works" label="Glass Works" />
                  <FormikCheckbox name="hazchem" label="HazChem" />
                  <FormikCheckbox name="hazfreight" label="HazFreight" />
                  <FormikCheckbox name="hazpacks" label="HazPacks" />
                  <FormikCheckbox name="hi-ab" label="HI-AB" />
                  <FormikCheckbox name="lgv_driver" label="LGV Driver" />
                  <FormikCheckbox name="low_loader" label="Low Loader" />
                  <FormikCheckbox name="mobile_crane" label="Mobile Crane" />
                  <FormikCheckbox name="moffat_crane" label="Moffat Crane" />
                  <FormikCheckbox
                    name="parcel_delivery"
                    label="Parcel Delivery"
                  />
                  <FormikCheckbox name="pcv" label="PCV" />
                  <FormikCheckbox
                    name="refrigerated_vehicles"
                    label="Refrigerated Vehicles"
                  />
                  <FormikCheckbox
                    name="refuse_collection"
                    label="Refuse Collection"
                  />
                  <FormikCheckbox name="removals" label="Removals" />
                  <FormikCheckbox name="road_sweeper" label="Road Sweeper" />
                  <FormikCheckbox name="skips" label="Skips" />
                  <FormikCheckbox
                    name="specialised_vehicles"
                    label="Specialised Vehicles"
                  />
                  <FormikCheckbox
                    name="splitter_gearboxes"
                    label="Splitter Gearboxes"
                  />
                  <FormikCheckbox name="steel" label="Steel" />
                  <FormikCheckbox
                    name="digital_tacho_card"
                    label="Digital Tacho Card"
                  />
                  <FormikCheckbox name="tacho" label="Tacho" />
                  <FormikCheckbox name="tail_lifts" label="Tail Lifts" />
                  <FormikCheckbox name="tanker" label="Tanker" />
                  <FormikCheckbox
                    name="timber_distibution"
                    label="Timber Distribution"
                  />
                  <FormikCheckbox name="tipper" label="Tipper" />
                  <FormikCheckbox
                    name="tractor_driver"
                    label="Tractor Driver"
                  />
                  <FormikCheckbox
                    name="wagon_and_drag"
                    label="Wagon &amp; Drag"
                  />
                  <FormikCheckbox
                    name="walking_floor_trailers"
                    label="Walking Floor Trailers"
                  />
                </Col2>
                <h3>7.5 Tonnes</h3>
                <Col2>
                  <FormikCheckbox
                    name="large_and_small_vans"
                    label="Large &amp; small vans"
                  />
                  <FormikCheckbox name="light_vans" label="Light vans" />
                  <FormikCheckbox name="catalogue" label="Catalogue" />
                  <FormikCheckbox name="multidrop_10" label="Multidrop 10" />
                  <FormikCheckbox name="multidrop_20" label="Multidrop 20" />
                  <FormikCheckbox name="multidrop_30" label="Multidrop 30" />
                  <FormikCheckbox name="multidrop_40" label="Multidrop 40" />
                  <FormikCheckbox
                    name="multidrop_50_plus"
                    label="Multidrop 50 +"
                  />
                  <FormikCheckbox
                    name="parcel_delivery_7_tonnes"
                    label="Parcel delivery 7 tonnes"
                  />
                </Col2>
              </section>
              <section>
                <h2>Drivers Declaration Form</h2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="motor_offence"
                  label="Have you at any time in the last five years been convicted of any motoring
                Offence?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="license_suspended"
                  label="Have you during the past ten years had your license suspended?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="prosecution_pending"
                  label="At the date of signing this form is there any prosecution pending or had anything occurred which may result in a future prosecution?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="physical_mental_defect"
                  label="Have you to your knowledge any physical or mental defect or infirmity or do you suffer from diabetes, heart complaint or any other diseases which may impair your driving efficiency?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                <p>
                  By signing this form I agree to inform you of anything
                  occuring in the future, which may result in a Prosecution or
                  of my contracting any disease mentioned In 4 above
                </p>
              </section>
              <section>
                <h3>7.5t and above drivers to complete</h3>
                <p>
                  I certify that I have worked the following hours during the
                  past seven days:
                </p>
                <Col2>
                  <FormikInput
                    name="monday_day"
                    type="text"
                    label="Monday - day"
                  />
                  <FormikInput
                    name="monday_night"
                    type="text"
                    label="Monday - night"
                  />
                </Col2>
                <hr />
                <Col2>
                  <FormikInput
                    name="tuesday_day"
                    type="text"
                    label="Tuesday - day"
                  />
                  <FormikInput
                    name="tuesday_night"
                    type="text"
                    label="Tuesday - night"
                  />
                </Col2>
                <hr />
                <Col2>
                  <FormikInput
                    name="wednesday_day"
                    type="text"
                    label="Wednesday - day"
                  />
                  <FormikInput
                    name="wednesday_night"
                    type="text"
                    label="Wednesday - night"
                  />
                </Col2>
                <hr />
                <Col2>
                  <FormikInput
                    name="thursday_day"
                    type="text"
                    label="Thursday - day"
                  />
                  <FormikInput
                    name="thursday_night"
                    type="text"
                    label="Thursday - night"
                  />
                </Col2>
                <hr />
                <Col2>
                  <FormikInput
                    name="friday_day"
                    type="text"
                    label="Friday - day"
                  />
                  <FormikInput
                    name="friday_night"
                    type="text"
                    label="Friday - night"
                  />
                </Col2>
                <hr />
                <Col2>
                  <FormikInput
                    name="saturday_day"
                    type="text"
                    label="Saturday - day"
                  />
                  <FormikInput
                    name="saturday_night"
                    type="text"
                    label="Saturday - night"
                  />
                </Col2>
                <hr />
                <Col2>
                  <FormikInput
                    name="sunday_day"
                    type="text"
                    label="Sunday - day"
                  />
                  <FormikInput
                    name="sunday_night"
                    type="text"
                    label="Sunday - night"
                  />
                </Col2>

                <p>
                  I undertake to inform you of the number of hours I have driven
                  should I at any time work as a driver on my own behalf, or on
                  behalf of any person, firm or company, at, between or during
                  any period(s) of driving that may do as a result of any
                  induction by you and between the time of completing this
                  application form and accepting my first assignment. I
                  acknowledge that it is my responsibility to ensure that
                  sufficient tachograph charts are issued to me by the user and
                  I shall ensure that these are returned to the user within 21
                  days of date completion.
                </p>
              </section>
              <section>
                <h2>INAL Record</h2>
                <p>
                  The information you provide is NOT a short listing toll in the
                  recruitment process.
                </p>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="criminal_record_disclosure"
                  label="Do you hold a Criminal Records Bureau Disclosure?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                {values.criminal_record_disclosure === "Yes" ? (
                  <FormikText
                    name="criminal_record_disclosure_details"
                    label="If yes please give details of Company and when"
                  />
                ) : null}
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="criminal_offence_under_rehab_act"
                  label="Have you ever been convicted of a criminal offence which is not spent under the Rehabilitation of Offenders Act 1974?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                {values.criminal_offence_under_rehab_act === "Yes" ? (
                  <FormikText
                    name="criminal_offence_under_rehab_act_details"
                    label="If yes please give details"
                  />
                ) : null}
                <p>
                  The Rehabilitation of Offenders Act 1974 (Exceptions) order
                  (975 requires us to ask you for additional information as you
                  may come into contact with either vulnerable adults or
                  children in some of the opportunities we have at present. A
                  criminal record check from the Criminal Records Bureau may be
                  required where this type of work is sought.
                </p>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="previous_convictions"
                  label="Do you have any previous convictions, whether or not they are 'spent' within the Act, Including any cautions, reprimands, final warning, bind-overs or any convictions from overseas?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                {values.previous_convictions === "Yes" ? (
                  <FormikText
                    name="previous_convictions_details"
                    label="If yes please give details"
                  />
                ) : null}
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="security_clearance"
                  label="Do you hold any form of current security clearance?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                {values.security_clearance === "Yes" ? (
                  <Col2>
                    <FormikDatepicker
                      name="security_clearance_date_start"
                      label="Date granted"
                      placeholderText="Select a date"
                    />
                    <FormikDatepicker
                      name="security_clearance_date_end"
                      label="Expiry date"
                      placeholderText="Select a date"
                    />
                    <FormikInput
                      name="security_clearance_place_of_work"
                      type="text"
                      label="Place of work when level of clearance granted:"
                    />
                  </Col2>
                ) : null}
              </section>
              <section>
                <h3>Declaration</h3>
                <p>
                  I confirm that the information provided-on this form and
                  within my-CV,-if applicable, is both truthful and accurate.
                  have omitted no facts that could affect my future employment.
                  I consent to any sensitive or persona! data, disclosed as part
                  of my application, being used Jn connection with the search
                  for work, subject to the relevant Data Protection legislation.
                </p>
                <p>
                  I will inform Pin Point immediately of any circumstances that'
                  may affect my work, such as changes to health. subsequent
                  pending prosecutions or convictions, which may arise whilst am
                  registered for permanent or temporary work. I understand that
                  Pin Point has the right to request a Criminal Records Bureau
                  Disclosure where they consider it necessary and that any.
                  details from this, or any other police checks provided. may be
                  forwarded to a potential employer.
                </p>
                <p>
                  Alt information provided will be treated in the strictest
                  confidence and will be processed and held in accordance with
                  relevant Data Protection Legislation.
                </p>
              </section>
              <section>
                {/* <section>
                  <h2>Contract of Employment</h2>
                  <p>
                    (1){" "}
                    <strong>
                      {values.first_name && values.surname
                        ? values.first_name + " " + values.surname
                        : "___________"}
                    </strong>{" "}
                    ("the Employee"), referred to throughout this Agreement as
                    "you" and "your" shaft be construed accordingly.
                  </p>
                  <p>
                    (2) Nova Payroll Management Services Limited, Company Number
                    07601096, trading as PIN POINT RECRUITMENT of Unit E14,
                    Marquis court, Team Valley, Gateshead NEII ORIJ ("the
                    Company")
                  </p>
                  <ol>
                    <li>
                      <strong>Definitions and interpretation</strong>
                      <ol>
                        <li>
                          <p>In these Terms the following definitions apply:</p>
                          <Col2>
                            <p>"Actual Rate of Pay"</p>
                            <p>
                              means, unless and until the Employee has completed
                              the Qualifying Period, the rate of pay which will
                              be paid for each hour worked during an Assignment
                              (to the nearest quarter hour) weekly in arrears,
                              subject to Deductions and any Agreed Deductions,
                              as set out in the relevant Assignment Details
                              Form;
                            </p>
                            <p>"Actual QP Rate of Pay"</p>
                            <p>
                              means the rate of pay which will be paid to the
                              Employee if and when s/he completes the Qualifying
                              Period. Such rate will be paid for each hour
                              worked during an Assignment (to the nearest
                              quarter hour) weekly in arrears, subject to
                              Deductions and any Agreed Deductions, as set out
                              in any variation to the relevant Assignment
                              Details Form, means the Agency Workers Regulations
                              2010
                            </p>
                            <p>"Agency Workers Regulations"</p>
                            <p>means the Agency Workers Regulations 2010</p>
                            <p>"Agreed Deductions"</p>
                            <p>
                              means any deductions the Employee has agreed can
                              be made from their pay
                            </p>
                            <p>"Assignment"</p>
                            <p>
                              means assignment services to be performed by the
                              Employee for the Client for a period of time
                              during which the Employee is assigned by the
                              Company to work temporarily for and under the
                              supervision and direction of the Client;
                            </p>
                            <p>"Assignment Details Form"</p>
                            <p>
                              means written confirmation of the assignment
                              details to be given to the Employee upon
                              acceptance of an Assignment;
                            </p>
                            <p>"Calendar Week"</p>
                            <p>
                              means any period of seven days starting with the
                              same day as the first day of the First Assignment;
                            </p>
                            <p>"Client"</p>
                            <p>
                              means the person. firm or corporate body together
                              with any subsidiary or associated person, firm or
                              corporate body (as the case may be) to whom the
                              Employee is assigned or introduced by the company;
                            </p>
                            <p>"Client's Group"</p>
                            <p>
                              means (a) any individual, company, partnership,
                              statutory body or other entity which from time to
                              time Controls the Client, including (but not
                              limited to) as a holding company as defined in
                              section 1159 of the Companies Act 2006; and (b)
                              any company, partnership, statutory body or other
                              entity which from time to time is Controlled by or
                              is under common Control with the Client, including
                              (but not limited to) as a subsidiary or holding
                              company as defined in section 1159 of the
                              Companies Act 2006;
                            </p>
                            <p>"Conduct Regulations"</p>
                            <p>
                              means the Conduct of Employment Agencies and
                              Employment Businesses Regulations 2003
                            </p>
                            <p>"Confidential Information"</p>
                            <p>
                              means any and all confidential commercial,
                              financial, marketing, technical or other
                              information or data of whatever nature relating to
                              the Client or Company or their business or affairs
                              (including but not limited to this Agreement,
                              data, records, reports, agreements, software,
                              programs, specifications. know-how. trade secrets
                              and other information concerning the Assignment)
                              in any form or medium whether disclosed or granted
                              access to whether in writing. orally or by any
                              other means, provided to the Employee or any third
                              party in relation to the Assignment by the Client
                              or the Company or by a third party on behalf of
                              the Client whether before or after the date of
                              this Agreement together with any reproductions of
                              such information in any form or medium or any
                              part(s) of such information;
                            </p>
                            <p>"Control"</p>
                            <p>
                              means (a) the legal or beneficial ownership,
                              directly or indirectly, of more than 50% of the
                              issued share capital or similar right of
                              ownership; or (b) the power to direct or cause the
                              direction of the affairs and/or general management
                              of the company, partnership. statutory body or
                              other entity in question, whether through the
                              ownership of voting capital, by contract or
                              otherwise, and "Controls" and "Controlled" shall
                              be construed accordingly;
                            </p>
                            <p>"Data Protection Laws"</p>
                            <p>
                              means the Data Protection Act 1998, any applicable
                              statutory or regulatory provisions and all
                              European Directives and regulations in force from
                              time to time relating to the protection and
                              transfer of personal data;
                            </p>
                            <p>"Deductions"</p>
                            <p>
                              means any deductions which the Company may be
                              required by law to make and in particular in
                              respect of PAYE income tax and National Insurance
                              Contributions;
                            </p>
                            <p>"Emoluments"</p>
                            <p>
                              means any pay in addition to the Actual QP Rate of
                              Pay;
                            </p>
                            <p>"Engagement"</p>
                            <p>
                              means the engagement, employment or use of the
                              Employee by the Client or by any third party to
                              whom the Employee has been introduced by the
                              Client on a permanent or temporary basis, whether
                              under a contract of service or for services; an
                              agency, license, franchise or partnership
                              arrangement; or any other engagement; or through
                              any other employment business; or through a
                              limited company of which the Employee is an
                              officer, employee or other representative; and
                              "Engage", "Engages" and "Engaged" shall be
                              construed accordingly;
                            </p>
                            <p>"First Assignment"</p>
                            <div>
                              <p>Means:</p>
                              <ol style={{ listStyle: "lower-alpha" }}>
                                <li>the relevant Assignment; or</li>
                                <li>
                                  if, prior to the relevant Assignment:
                                  <p>
                                    the Employee has worked in any assignment in
                                    the same role with the relevant Client as
                                    the role in which the Employee works in the
                                    relevant Assignment: and
                                  </p>
                                  <p>
                                    the relevant Qualifying Period commenced in
                                    any such assignment, that assignment (an
                                    assignment being (for the purpose of this
                                    defined term) a period of time during which
                                    the Employee is supplied by one or more
                                    Temporary Work Agencies to the relevant
                                    Client to work temporarily for and under the
                                    supervision and direction of the relevant
                                    Client);
                                  </p>
                                </li>
                              </ol>
                            </div>
                            <p>"Hourly Rate"</p>
                            <p>
                              means the minimum rate of pay (subject to
                              Deductions) that the Company reasonably expects to
                              achieve, for all hours worked by the Employee
                            </p>
                            <p>"Leave Year"</p>
                            <p>
                              means the period of employment during which the
                              Employee accrues and may take statutory leave
                              commencing on 1 January and expiring on 31
                              December of a calendar year
                            </p>
                            <p>"Period of Extended Hire"</p>
                            <p>
                              means any additional period that the Client wishes
                              the Employee to be supplied for beyond the
                              duration of the original Assignment or series of
                              assignments as an alternative to paying a Transfer
                              Fee;
                            </p>
                            <p>"Qualifying Period"</p>
                            <p>
                              means 12 continuous Calendar Weeks during the
                              whole or part of which the Employee is supplied by
                              one or more Temporary Work Agencies to the
                              relevant Client to work temporarily for and under
                              the supervision and direction of the relevant
                              Client in the same role, and as further defined in
                              the Schedule to this Agreement;
                            </p>
                            <p>"Relevant Period"</p>
                            <p>
                              means (a) the period of 8 weeks commencing on the
                              day after the last day on which the Employee
                              worked for the Client having been supplied by the
                              Company; or (b) the period of 14 weeks commencing
                              on the first day on which the Employee worked for
                              the Client having been supplied by Company or 14
                              weeks from the first day of the most recent
                              Assignment where there has been a break of more
                              than 6 weeks (42 days) since any previous
                              assignment
                            </p>
                            <p>"Temporary Work Agency"</p>
                            <p></p>
                            <p>"Transfer Fee"</p>
                            <p></p>
                            <p>"Working Time Regulations"</p>
                            <p>means the Working Time Regulations 1998</p>
                          </Col2>
                        </li>
                        <li>
                          Unless the context otherwise requires, references to
                          the singular include the plural and references to the
                          masculine include the feminine and vice versa.
                        </li>
                        <li>
                          The headings contained in this Agreement are for
                          convenience only and do not affect their
                          interpretation.
                        </li>
                        <li>
                          Any reference, express or implied, to an enactment
                          includes a reference to that enactment as from time to
                          time amended, modified, extended, re-enacted, replaced
                          or applied by or under any other enactment (whether
                          before or after the commencement date of this
                          Agreement) and all subordinate legislation made
                          (before or after this Agreement) under it from time to
                          time.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>The contract</strong>
                      <ol>
                        <li>
                          This Agreement is issued in accordance with section 1
                          of the Employment Rights Act 1996 and is to be
                          supplemented by the Assignment Details Form and which
                          together form your contract of employment between you
                          and the Company. ff there is any conflict between the
                          tens of this Agreement and the terms of any relevant
                          Assignment Details Form, the terms of the relevant
                          Assignment Details Form shall take precedence.
                        </li>
                        <li>
                          Any prior agreements or arrangements (written or oral,
                          express or implied) between you and the Company
                          relating to or arising out of your employment other
                          than any Assignment Details Forms are hereby cancelled
                          and superseded by this Agreement.
                        </li>
                        <li>
                          Any reference, express or implied, to an enactment
                          within this Agreement includes a reference to that
                          enactment as from time to time amended, modified,
                          extended, re-enacted. replaced or applied by or under
                          any other enactment (whether before or after the date
                          of this Agreement) and all subordinate legislation
                          made (before or after this Agreement) under it from
                          time to time.
                        </li>
                        <li>
                          No variation or alteration to this Agreement shall be
                          valid unless the details of such variation are agreed
                          between you and the Company and set out in writing and
                          a copy of the varied terms is given to you stating the
                          date on or after which such varied terms shall apply.
                        </li>
                        <li>
                          The Company shall act as an employment business (as
                          defined in Section 13(3) of the Employment Agencies
                          Act 1973 when introducing or supplying you for
                          Assignments with Clients.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Commencement and durarion of employment</strong>
                      <ol>
                        <li>
                          You will be engaged under a contract of employment and
                          your employment under this Agreement will begin on the
                          commencement date of your first Assignment as
                          specified in the first relevant Assignment Details
                          Form and will continue unless and until terminated in
                          accordance with the notice provisions at clause 14
                          below.
                        </li>
                        <li>
                          Your period of continuous employment with the Company
                          wili begin on the date your employment begins as set
                          out in sub-clause 3.1 above and no other previous
                          period of work with the Company or any third party
                          counts towards your continuous employment.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Job title and duties</strong>
                      <ol>
                        <li>
                          Your job title wilt be confirmed in each Assignment
                          Details Form.
                        </li>
                        <li>
                          Your normal duties will entail you being assigned to
                          various Clients of the Company who have requested the
                          Company to provide them with temporary labour. Further
                          details of each Assignment will be confirmed to you in
                          relevant Assignment Details Forms.
                        </li>
                        <li>
                          As soon as possible prior to the commencement of each
                          Assignment and during each Assignment (as appropriate)
                          and at any time at the Company's request you undertake
                          to:
                        </li>
                        <li>
                          <ol>
                            <li>
                              inform the Company of any Calendar Weeks between 1
                              October 2011 and prior to the date of commencement
                              of the relevant Assignment and/or during the
                              relevant Assignment in which you have worked in
                              the same or a similar role with the relevant
                              Client via any third party and which you believe
                              count or may count toward the Qualifying Period;
                              and
                            </li>
                            <li>
                              Provide the Company with all the details of such
                              work, including (without limitation) details of
                              where, when and the period(s) during which such
                              work was undertaken and any other details
                              requested by the Company; and inform the Company
                              if, since 1 October 2011 you have prior to the
                              date of commencement of the relevant Assignment
                              and/or during the relevant Assignment:
                            </li>
                            <ol>
                              <li>
                                completed two or more assignments with the
                                Client;
                              </li>
                              <li>
                                completed at least one assignment with the
                                Client and one or more earlier assignments with
                                any member of the Client's Group; and/or
                              </li>
                              <li>
                                Worked in more than two roles during an
                                assignment with the Client and on at least two
                                occasions worked in a role that was not the same
                                role as the previous role.
                              </li>
                            </ol>
                          </ol>
                          <p>
                            The Company will take reasonable steps to find you
                            suitable work with its Clients and you agree to
                            accept all such Assignments offered to you by the
                            Company. The Company may be entitled to terminate
                            your employment on notice in accordance with clause
                            14 below if you refuse to accept suitable
                            Assignments. Your refusal of a suitable Assignment
                            may constitute gross misconduct under the Company's
                            disciplinary procedure entitling the Company to
                            terminate your employment with immediate effect
                            pursuant to clause 14.2 below.
                          </p>
                        </li>
                        <li>
                          In the event that the Company is unable to assign you
                          to any of its Clients for any period of time you agree
                          that you will remain contactable by telephone so that
                          the Company can offer you suitable work as soon as it
                          becomes available. You must notify the Company
                          immediately if you are not available to undertake
                          Assignments at any time during the period of this
                          Agreement and shall comply fully with any notification
                          requirements specified by the Company in this regard.
                          Failure to notify the Company of your unavailability
                          shall constitute a disciplinary offence because you
                          may receive payment to which you are not entitled and
                          any such failure may result in the termination of your
                          employment with immediate effect pursuant to clause
                          14.2 below.
                        </li>
                        <li>
                          While you are on Assignment with any of the Company's
                          Clients you shall:
                          <p>
                            1 - co-operate with the Client's staff and accept
                            the direction, supervision and instruction of any
                            responsible person in the Client's organisation;
                          </p>
                          <p>
                            2 - follow any of the Client's rules and
                            regulations, including without limitation those
                            regarding health and safety, to which your attention
                            has been drawn;
                          </p>
                          <p>
                            3 - not engage in any conduct detrimental to the
                            interests of the Company and/or the Client which
                            includes any conduct which could bring the Company
                            and/or the Client into disrepute and/or which
                            results in the loss of custom or business by either
                            the Company or the Client;
                          </p>
                          <p>
                            4 - not commit any act or omission constituting
                            unlawful discrimination against or harassment of any
                            member of the Company's or the Client's staff;
                          </p>
                          <p>
                            5 - not at any time divulge to any person, nor use
                            for your own or any other person's benefit, any
                            Confidential Information relating to the Client's or
                            the Company's employees, business affairs,
                            transactions or finances;
                          </p>
                          <p>
                            6- on completion of the Assignment or at any time
                            when requested by the Client or the Company, return
                            to the Client or where appropriate, to the Company,
                            any Client property or items provided to you in
                            connection with or for the purpose of the
                            Assignment, including, but not limited to any
                            equipment, materials, documents, swipe cards or ID
                            cards, uniforms, personal protective equipment or
                            clothing.
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Other employment</strong>
                      <ol>
                        <li>
                          During the period of each and every Assignment, you
                          must devote the whole of your time, attention and
                          abilities during your normal hours of work to your
                          duties for the Company. You may not, under any
                          circumstances. undertake any other duties of whatever
                          kind for any third party during your normal hours of
                          work on any Assignment for the Company without the
                          prior written consent of the Company. Such consent
                          will not normally be withheld provided that the other
                          work is not, in the opinion of the Company, likely to
                          cause you to breach the terns of this Agreement and/or
                          any relevant Assignment Details Form.
                        </li>
                        <li>
                          If, before or during an Assignment or during the
                          Relevant Period, the Client wishes to Engage you
                          directly or through another employment business. you
                          acknowledge that the Company will be entitled either
                          to charge the Client a fee or to agree an extension of
                          the Assignment with the Client at the end of which you
                          may be engaged directly by the Client or through
                          another employment business without further charge to
                          the Client. In addition the Company will be entitled
                          to charge a fee to the Client if the Client introduces
                          you to a third party who subsequently engages you
                          within the aforementioned periods
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Infromation to be provided</strong>
                      <ol>
                        <li>
                          At the same time as an Assignment is offered to you
                          the Company shall provide you with an Assignment
                          Details Form setting out the following:
                          <ol>
                            <li>
                              The identity of the Client, and if applicable the
                              nature of their business;
                            </li>
                            <li>
                              the date the Assignment is to commence and the
                              duration or likely duration of Assignment;
                            </li>
                            <li>
                              the type of work, location and hours during which
                              you would be required to work;
                            </li>
                            <li>
                              the Actual Rate of Pay or Actual QP Rate of Pay
                              (as appropriate) that will be paid and any
                              expenses payable by or to you;
                            </li>
                            <li>
                              any risks to health and safety known to the Client
                              in relation to the Assignment and the steps the
                              Client has taken to prevent or control such risks;
                              and
                            </li>
                            <li>
                              what experience, training, qualifications and any
                              authorisation required by law or a professional
                              body the Client considers necessary or which are
                              required by law to work in the Assignment.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Where such information is not given in paper form or
                          by electronic means it shall be confirmed by such
                          means by the end of the third business day (excluding
                          Saturday, Sunday and any Public or Bank Holiday)
                          following save where:
                          <ol>
                            <li>
                              you are being offered an Assignment in the same
                              position as one in which you have previously been
                              supplied within the previous 5 business days and
                              such information has already been given to you and
                              remains unchanged; or
                            </li>
                            <li>
                              subject to clause 6.3, the Assignment is intended
                              to last for 5 consecutive business days or less
                              and such information has previously been given to
                              you before and remains unchanged, the Company
                              needs only to provide written confirmation of the
                              identity of the Client and the likely duration of
                              the Assignment.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Where the provisions of clause 62.2 are met but the
                          Assignment extends beyond the intended 5 consecutive
                          business day period, the Company shall provide such
                          information set out in clause 6.1 •to you in paper or
                          electronic form within 8 days of the start of the
                          Assignment.
                        </li>
                        <li>
                          For the purpose of calculating the average number of
                          weekly hours worked by you on an Assignment for the
                          purposes of the Working Time Regulations, the start
                          date for the relevant averaging period shall be the
                          date on which you commence the first Assignment.
                        </li>
                        <li>
                          If you have completed the Qualifying Period on the
                          start date of the relevant Assignment or following
                          completion of the Qualifying Period during the
                          relevant Assignment, and if you are entitled to any
                          terms and conditions relating to the duration of
                          working time, night work, rest periods and/or rest
                          breaks under the Agency Workers Regulations which are
                          different and preferential to rights and entitlements
                          relating to the same under the Working Time
                          Regulations, any such terms and conditions will be as
                          set out in the relevant Assignment Details Form or any
                          variation to the relevant Assignment Details Form (as
                          appropriate).
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Location of work</strong>
                      <p>
                        You will be required to work for Clients of the Company
                        at various locations within the UK. The exact address of
                        each Assignment will be confirmed to you in the relevant
                        Assignment Details Form. Assignments outside of this
                        area may be offered to you by the Company. For the
                        avoidance of doubt, the Company regards total daily
                        commuting time of under three hours to be reasonable.
                      </p>
                    </li>
                    <li>
                      <strong>Pay</strong>
                      <ol>
                        <li>
                          During periods when you are carrying out Assignments
                          for Clients of the Company you will be paid the Hourly
                          Rate. The exact amount of your pay (the Actual Rate of
                          Pay) for any particular Assignment will be set out in
                          the Relevant Assignment Details Form.
                        </li>
                        <li>
                          If you have completed the Qualifying Period on the
                          start date of the relevant Assignment or following
                          completion of the Qualifying Period during the
                          relevant Assignment, the Company shall pay you:
                          <ol>
                            <li>the Actual QP Rate of Pay; and</li>
                            <li>the Emoluments (if any),</li>
                          </ol>
                          which will be notified on a per Assignment basis and
                          as set out in the relevant Assignment Details Form or
                          any variation to the relevant Assignment Details Form.
                        </li>
                        <li>
                          If you have completed the Qualifying Period on the
                          start date of the relevant Assignment or following
                          completion of the Qualifying Period during the
                          relevant Assignment, you may be entitled to receive a
                          bonus. You will comply with any requirements of the
                          Company and/or the Client relating to the assessment
                          of your performance for the purpose of determining
                          whether or not you are entitled to a bonus and the
                          amount of any such bonus. If, subject to satisfying
                          the relevant assessment criteria, you are entitled to
                          receive a bonus, the Company will pay the bonus to
                          you.
                        </li>
                        <li>
                          Subject to any statutory entitlement under the
                          relevant legislation referred to in clauses 11.4 and
                          13 below and any other statutory entitlement, you will
                          not be entitled to be paid during rest periods, lunch
                          breaks. time spent travelling to and from work and
                          during periods when you are not working on an
                          Assignment (including periods when the Company has
                          been unable to find you an Assignment or you have
                          chosen not to accept any Assignment offered to you).
                        </li>
                        <li>
                          Your pay will be paid weekly in arrears by credit
                          transfer on a Friday. Late presentation of timesheets
                          may delay the Company in making payment to you.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Travel and subsistence scheme</strong>
                      <ol>
                        <li>
                          <p>
                            Pin Point Recruitment operates a salary sacrifice
                            travel and subsistence scheme (TSS) which is
                            available to all flexible workers who complete a
                            series of temporary assignments under the terms of
                            the contract of employment
                          </p>
                          <p>
                            Eligible workers agree to sacrifice an element of
                            gross taxable pay in return for travel and
                            subsistence allowances approved by HM Revenue and
                            Customs.
                          </p>
                        </li>
                        <li>
                          All workers are initially opted into the TSS upon
                          commencement of employment. If you decides that you do
                          not wish to participate in the TSS then you may opt
                          out of the scheme at any time in the first four weeks
                          of employment by completing a TSS Opt Out form and
                          then again after 10 months of employment by the same
                          method. After this period you will remain in the
                          scheme for a renewable period of 12 months or until
                          your employment with the company terminates. If you
                          wish to opt back into the scheme having previously
                          opted out, you must wait 12 months from the period
                          that you decided to opt out.
                        </li>
                        <li>
                          If your contract of employment is terminated by either
                          party you will automatically be removed from the TSS
                          and additionally if you have worked on the same
                          assignment continuously for in excess of 24 months
                          from the date that you joined the scheme, you will
                          cease to meet HM Revenue and Customs requirements for
                          being a flexible worker and your membership of the TSS
                          will cease.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Timesheets</strong>
                      <ol>
                        <li>
                          At the end of each week of an Assignment (or at the
                          end of the Assignment where it is for a period of 1
                          week or less or is completed before the end of a week)
                          you shall deliver to the Company a timesheet duly
                          completed to indicate the number of hours worked
                          during the preceding week (or such lesser period) and
                          signed by an authorised representative of the Client.
                        </li>
                        <li>
                          Subject to the provisions of clause 10.3, the Company
                          shall pay you for all hours worked regardless of
                          whether the Company has received payment from the
                          Client.
                        </li>
                        <li>
                          Where you fail to submit a properly authenticated
                          timesheet the Company shall, in a timely fashion,
                          conduct further investigations into the hours claimed
                          by you and the reasons that the Client has refused to
                          sign a timesheet in respect of those hours. This may
                          delay any payment due to you. The Company shall make
                          no payment to you for hours claimed but not worked and
                          you may be subject to the Company's disciplinary
                          procedure.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Hours of work</strong>
                      <ol>
                        <li>
                          Whilst on Assignment, you will be required to work
                          such hours as are set out in the relevant Assignment
                          Details Form and in any event the normal hours of work
                          required by the Client.
                        </li>
                        <li>
                          You may be required to work/offered overtime in
                          addition to your normal hours of work by the Company
                          or the Client. If this is the case you will receive
                          additional payment for such overtime hours worked.
                        </li>
                        <li>
                          Subject to any amendments made to your basic working
                          and employment conditions during the term of this
                          Agreement in compliance with Regulation 5 of the
                          Agency Workers Regulations, time spent travelling to
                          and from the premises of the Company or its Clients
                          (apart from time spent travelling between two or more
                          premises of the Client), lunch breaks and other rest
                          breaks and periods during which the Company is not
                          able to offer you any Assignments shall not count as
                          part of your working time for the purpose of the
                          Working Time Regulations.
                        </li>
                        <li>
                          If you are entitled to any terns and conditions
                          relating to the duration of working time, night work,
                          rest periods and/or rest breaks under the Agency
                          Workers Regulations which are preferential to rights
                          and entitlements relating to the same under the
                          Working Time Regulations, any such terms and
                          conditions and the date from which they commence will
                          be as set out in the Assignment Details Form or any
                          amendments thereto.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Annual leave</strong>
                      <ol>
                        <li>
                          You are entitled to paid annual leave according to the
                          statutory minimum entitlement under the Working Time
                          Regulations. The current statutory entitlement to paid
                          annual leave under the Working Time Regulations is 5.6
                          weeks (pro rated for part-time workers).
                        </li>
                        <li>
                          Entitlement to payment for leave under clause 12.1
                          accrues in proportion to the amount of time worked by
                          you on Assignment during the Leave Year.
                        </li>
                        <li>
                          Under the Agency Workers Regulations, on completion of
                          the Qualifying Period you may be entitled to paid or
                          unpaid annual leave in addition to your entitlement to
                          paid annual leave under the Working Time Regulations
                          and in accordance with clauses 12.1 and 12.2. If this
                          is the case, any such entitlement(s), the date from
                          which any such entitlement(s) will commence and how
                          payment for such entitlement(s) accrues will be as set
                          out in the relevant Assignment Details Form or any
                          variation to the relevant Assignment Details Form.
                        </li>
                        <li>
                          <p>
                            All entitlement to annual leave must be taken during
                            the course of the Leave Year in which it accrues
                            and, save as may be set out in the relevant
                            Assignment Details Form or any variation to the
                            relevant Assignment Details Form, none may be
                            carried forward to the next year. You are
                            responsible for ensuring that all paid
                          </p>
                          <p>
                            annual leave is requested and taken within the
                            relevant Leave Year which runs from 1 January to 31
                            December each calendar year.
                          </p>
                        </li>
                        <li>
                          Unless stated otherwise in the Assignment Details
                          Form, if you wish to take paid annual leave you should
                          request such annual leave in writing from the Company,
                          setting out the dates of your intended absence
                          providing notice of at least twice the length of the
                          period of leave that you wish to take. The Company may
                          accept or decline your request depending on the
                          operational requirements of the Client for whom you
                          are carrying out an Assignment. Following any booking
                          of annual leave, the Company may give a counter notice
                          to you to postpone or reduce the amount of leave that
                          you wish to take and unless stated otherwise in the
                          Assignment Details Form, in such circumstances the
                          Company will inform you in writing giving at least the
                          same length of notice as the period of leave that it
                          wishes to postpone or reduce it by. The Company
                          reserves the right to ask you to take annual leave at
                          times convenient to its business and that of its
                          Clients.
                        </li>
                        <li>
                          ubject to clause 12.3 the amount of payment which you
                          will receive in respect of periods of annual leave
                          will be calculated in accordance with and paid in
                          proportion to the number of hours which you have
                          worked on Assignment. [Payments for annual leave will
                          be calculated on the basis of rates paid during the
                          Client's normal working hours i.e. those which do not
                          attract overtime rates of pay. You may not take more
                          than 2 weeks' holiday in any one month period. nor may
                          you during your first 12 months of employment take
                          holiday which has not accrued.
                        </li>
                        <li>
                          Upon termination of your employment, your holiday
                          entitlement for that Leave Year shall be in direct
                          proportion to the period employed in that Leave Year
                          and you shall be paid in lieu of any holiday
                          entitlement that was accrued but not taken at the date
                          of termination of employment, or. as the case may bet
                          you shall repay to the Company an amount in respect of
                          any holiday periods taken in excess of your holiday
                          entitlement for that year and you hereby authorise the
                          Company to take repayment of such monies by way of
                          deduction from any final payment owed to you.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Notification of absences and sick pay</strong>
                      <ol>
                        <li>
                          If you are unable to attend work for any reason and
                          your absence has not previously been authorised by the
                          Company you must inform the Company of the fact of
                          your absence and the full reasons for it by 9.30am on
                          each working day of absence. Once you have been absent
                          for a total of 7 days including weekends you must
                          provide the Company with a medical certificate or
                          statement of fitness for work on the eighth day of
                          sickness or injury if your absence is medically
                          related. Thereafter, medical certificates or
                          statements of.fitness for work must be provided to the
                          Company to cover any continued medical related
                          absence. If, on a medical certificate or statement of
                          fitness for work, your doctor recommends any
                          adjustments to your duties, hours or working
                          conditions to facilitate a return to work, you are
                          required to co-operate with the Company regarding the
                          possible implementation of such changes,
                          notwithstanding the fact that the advice on a
                          statement of fitness•for work is not binding on the
                          Company.
                        </li>
                        <li>
                          Immediately following your return to work after a
                          period of absence which has not previously been
                          authorised by the Company you are required to complete
                          a self-certification form (irrespective of whether you
                          have a medical certificate or statement of fitness for
                          work to cover part or all of the period of absence)
                          stating the dates of and the reason for your absence,
                          including details of sickness on non.working days as
                          this information is required by the Company for
                          calculating Statutory Sick Pay entitlement.
                          Self-certification forms will be retained in the
                          Company's records.
                        </li>
                        <li>
                          If you are absent from work due to sickness or injury
                          and comply with the requirements of this clause, you
                          will be paid Statutory Sick Pay in accordance with the
                          provisions of the Social Security Contributions &amp;
                          Benefits Act 1992. For Statutory Sick Pay purposes
                          your qualifying days are your normal working days.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Notice to terminate employment</strong>
                      <ol>
                        <li>
                          {" "}
                          The period of notice to be given by the Company to you
                          to terminate your employment is in accordance with the
                          minimum notice period provided for by the Employment
                          Rights Act 1986.
                        </li>
                        <li>
                          {" "}
                          Subject to clauseError! Reference source not found.
                          The period of notice to be given in writing by you to
                          the Company to terminate your employment under this
                          Agreement is one week.
                        </li>
                        <li>
                          In the event that you are found to have committed an
                          act of gross misconduct the Company will be entitled
                          to terminate your employment without notice or pay in
                          lieu of notice.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Disciplinary and grievance procedures</strong>
                      <ol>
                        <li>
                          Details regarding the Company's grievance,
                          disciplinary and dismissal procedures are annexed to
                          this Agreement (Appendix I). However such procedures
                          are non-contractual.
                        </li>
                        <li>
                          The Company expressly reserves the right to suspend
                          you from employment pending investigation and any
                          further action in relation to any disciplinary or
                          related matters, for such period as it considers
                          appropriate or until any disciplinary process has been
                          completed.
                        </li>
                        <li>
                          If, either before or during the course of an
                          Assignment, you become aware of any reason why you may
                          not be suitable for an Assignment, you shall notify
                          the Company without delay. A failure to notify the
                          Company under this clause shall constitute a
                          disciplinary offence.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Confidentiality</strong>
                      <ol>
                        <li>
                          You must not disclose any trade secrets or other
                          information of a confidential nature relating to the
                          Company or any of its Clients or any of their business
                          associates or in respect of which the Company or any
                          of its Clients owes an obligation of confidence to any
                          third party either during or after your employment
                          except in the proper course of your employment or as
                          required by law.
                        </li>
                        <li>
                          You must not remove any documents or tangible items
                          which belong to the Company or its Clients which
                          contain any Confidential Information from the
                          Company's/Client's premises at any time without proper
                          advance authorisation.
                        </li>
                        <li>
                          You must return to the Company upon request and, in
                          any event, upon the termination of your employment,
                          all property belonging to the Company or any of its
                          Clients or any of their business associates including
                          without limitation all documents and tangible items
                          including those which contain or refer to any
                          Confidential Information and which are in your
                          possession or under your control.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Health and safety at work</strong>
                      <ol>
                        <li>
                          The Company will take all reasonably practicable steps
                          to ensure your health, safety and welfare while at
                          work.
                        </li>
                        <li>
                          During every Assignment you will take all reasonable
                          steps to safeguard your own health and safety and that
                          of any other person who may be present or be affected
                          by your actions on the Assignment and comply with the
                          health and safety policies and procedures of the
                          Client.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Data protection</strong>
                      <ol>
                        <li>
                          You warrant that in relation to this Agreement you
                          will comply strictly with all provisions applicable to
                          you under the Data Protection Laws and shall not do or
                          permit to be done anything which might cause the
                          Company or the Client to breach any Data Protection
                          Laws.
                        </li>
                        <li>
                          You consent to the Company, any other intermediary
                          involved in supplying your services to the Client (now
                          or in the future), and the Client:
                          <ol>
                            <li>
                              processing your personal data for purposes
                              connected with the performance of the Assignment
                              and pursuant to this Agreement; and
                            </li>
                            <li>
                              Exporting and/or processing your personal data in
                              jurisdictions outside the European Economic Area
                              for purposes connected with the performance of
                              this Agreement.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Deductions</strong>
                      <p>
                        Subject to compliance with Regulation 12 of the Conduct
                        Regulations the Company reserves the right in its
                        absolute discretion to deduct from your pay any sums
                        which you may owe the Company including, without
                        limitation, any overpayments or loans made to you by the
                        Company or losses suffered by the Company as a result of
                        your negligence or breach of Company rules.
                      </p>
                    </li>
                    <li>
                      <strong>Jurisdictions and governing law</strong>
                      <p>
                        This Agreement shall be governed and construed in all
                        respects by English law and the parties irrevocably
                        submit to the non-exclusive jurisdiction of the Courts
                        of England and Wales
                      </p>
                    </li>
                  </ol>
                  <p>
                    The parties to this Agreement have read understood and agree
                    to be bound by its terms.
                  </p>
                </section>
               */}
              </section>
              <section>
                <h2>Mobile Worker's Working Time Declaration</h2>
                <Col2>
                  <FormikInput
                    name="temporary_worker_name"
                    type="text"
                    label="Temporary worker's name"
                  />
                  <FormikInput
                    name="employment_business"
                    type="text"
                    label="Name of employment business"
                  />
                </Col2>
                <hr />
                <h3>Road Transport (Working Time) Regulations</h3>
                <p>
                  The Road Transport (Working Time) Regulations (RTWT) requires
                  employment businesses to maintain records of the Working Time
                  of mobile workers who are involved in operations subject to
                  Community Regulation 561/2006. An employment business is
                  obliged to ensure that the Working Time limits specified below
                  are complied with for temporary workers they engage:
                </p>
                <h3>Summary of the RTWT Regulations:</h3>
                <p>
                  <strong>
                    In summary the RTWT regulations provide for the following:
                  </strong>
                </p>
                <p>
                  Mobile drivers (such as LGV drivers and crew) are subject to a
                  maximum average Working Time of 48-hours per week over a
                  default 17-week reference period; this default reference
                  period may be changed to a rolling reference period and
                  extended to 26 weeks in certain circumstances.
                </p>
                <p>
                  <strong>
                    There is a maximum weekly limit of 60 hours Working Time.
                  </strong>
                </p>
                <p>
                  There is a maximum of 10 hours night work within each 24-hour
                  period. Night time is defined as midnight to 4.00am (for goods
                  vehicles) and 01:00 and 5:00am (for passenger vehicles). This
                  maximum may be extended in certain circumstances.
                </p>
                <p>
                  Rest Periods: Mobile workers must take the following breaks:
                  30 minutes after 6 hours Working Time and 45 minutes for over
                  9 hours Working Time. It is important to note that EU Drivers
                  Hours breaks and rest periods still apply.
                </p>
                <p>
                  <strong>How we calculate your average Working Time</strong>
                </p>
                <p>
                  We will calculate your average Working Time over a 17-week
                  calendar reference period. The reference period we will use
                  begins on midnight on the nearest Monday morning on or after 1
                  April, 1 August and 1 December each year and ending at
                  midnight at the beginning of the nearest Monday falling on or
                  after 17 weeks of these dates. If we decide to operate a 17*
                  week rolling reference period we will confirm this in writing
                  to you.
                </p>
                <h3>Your responsibilities</h3>
                <p>
                  It is your responsibility to inform us of all your Working
                  Time during the reference periods we use and to keep us
                  updated as to any additional Working Time you undertake for
                  other employers or employment businesses for these purposes
                  during an assignment.
                </p>
                <p>
                  It is also your responsibility to accurately record your
                  Working Time during an assignment.
                </p>
                <br />
                <p>
                  RTWT Regulations have been introduced to protect and safeguard
                  the health and safety of mobile workers, other road users and
                  the public. A mobile worker also has a responsibility for
                  complying with the regulations. If the mobile worker knowingly
                  breaks the rules (e.g. neglects to inform his employer or
                  employment business about other work, or knowingly makes a
                  false record), then they will committing a criminal offence
                  and may subject to a fine on conviction of up to E5000.
                  (Regulation 18 of the RTWT Regulations.)
                </p>
                <p>
                  The RTW T Regulations and EU Drivers Hours Rules require that
                  all work undertaken for other employers must be taken into
                  account when we calculate your Working Time during the
                  relevant reference period. Both transport and non-transport
                  work must be taken into account.
                </p>
                <p>
                  Accordingly, please provide details of Working Time undertaken
                  for any other employers or employment businesses during the
                  current 17-week reference period. See above for the dates of
                  the relevant 17-week reference period.
                </p>
                <p>
                  Both transport and non-transport working time must be
                  declared. If you are engaged jn charitable or voluntary work,
                  or work for the TA, fire service or police you must inform us
                  and you should fill out the chart below as if you were engaged
                  in other work, even though we may exclude time spent on such
                  activities from our calculation of your Working Time.
                </p>
                <h3>Mobile worker declarations</h3>
                <p>
                  <strong>
                    Please complete one of the following two declarations:
                  </strong>
                </p>
                <p>Option 1 — Sole engagement declaration</p>
                <p>
                  <strong>
                    I{" "}
                    <strong>
                      {values.first_name && values.surname
                        ? values.first_name + " " + values.surname
                        : "___________"}
                    </strong>
                    . declare that:{" "}
                  </strong>
                </p>
                <p>
                  I will accurately detail all my Working Time during the
                  current reference period and that it is true and correct to
                  the best of my knowledge; and I AM NOT currently engaged in
                  any work, other than for Pin Point Recruitment which needs to
                  be included in the calculations of my Working Time under the
                  RTWT Regulations; and I will keep Pin Point Recruitment
                  informed in writing as to the details of Working Time
                  undertaken for other employers or employment businesses on a
                  weekly basis; and I will inform Pin Point Recruitment
                  immediately if there are any changes to my situation which
                  impacts on my Working Time.
                </p>
                <hr />
                <p>Option 1 — Multi engagement declaration</p>
                <p>
                  <strong>
                    I{" "}
                    <strong>
                      {values.first_name && values.surname
                        ? values.first_name + " " + values.surname
                        : "___________"}
                    </strong>
                    . declare that:{" "}
                  </strong>
                </p>
                <p>
                  I have accurately detailed all my Working Time during the
                  current reference period in the above chart and that it is
                  true and correct to the best of my knowledge; and
                </p>
                <p>
                  I AM currently engaged in other work in addition to my work
                  for Pin Point Recruitment which needs to be included the
                  calculations of my Working Time under the RTWT Regulations;
                  and I will keep Pin Point Recruitment informed in writing as
                  to the details of Working Time undertaken for other employers
                  or employment businesses on a weekly basis; and I will inform
                  Pin Point Recruitment immediately if there are any changes to
                  my situation which impacts on my Working Time.
                </p>
              </section>
              <section>
                <h2>Tachograph test</h2>
                <ol>
                  <Col2>
                    <li>
                      <FormikInput
                        name="hours_drive"
                        type="text"
                        label="How many hours can you drive in a day?"
                      />
                    </li>
                    <li>
                      <FormikInput
                        name="weekly_rest_period"
                        type="text"
                        label="What is a standard weekly rest period?"
                      />
                    </li>
                    <li>
                      <FormikInput
                        name="daily_rest_period"
                        type="text"
                        label="What is a standard daily rest period?"
                      />
                    </li>
                  </Col2>
                  <Col2>
                    <li>
                      <FormikInput
                        name="hours_drive_till_break"
                        type="text"
                        label="How many hours can you drive for before you need to stop for your break? How long is the required break?"
                      />
                    </li>
                    <li>
                      <FormikInput
                        name="hours_work_till_break"
                        type="text"
                        label="How many hours can you work for before you need to stop for a break? How long is the required break?"
                      />
                    </li>
                  </Col2>
                  <li>
                    <FormikSelect
                      label="What is the definition of a fortnight?"
                      name="fortnight_definition"
                      options={[
                        {
                          value: "A fixed period of two weeks",
                          label: "A fixed period of two weeks",
                        },
                        {
                          value: "A rolling period any consecutive weeks",
                          label: "A rolling period any consecutive weeks",
                        },
                        {
                          value: "The last two weeks of any month",
                          label: "The last two weeks of any month",
                        },
                      ]}
                    />
                  </li>
                  <li>
                    <FormikInput
                      name="photo_card_expired"
                      type="text"
                      label="Can you drive once your photo card has expired on your driving license?"
                    />
                  </li>
                  <li>
                    <FormikInput
                      name="break_while_carrying_out_other_work"
                      type="text"
                      label="Can you take a break whilst carrying out other work?"
                    />
                  </li>
                  <li>
                    <FormikSelect
                      label="Your vehicle is loaded by the yard man and you feel the load is unsafe. Do you?"
                      name="loader_feels_unsafe"
                      options={[
                        {
                          value: "Take the load out regardless",
                          label: "Take the load out regardless",
                        },
                        {
                          value: "Ask for the vehicle to be reloaded",
                          label: "Ask for the vehicle to be reloaded",
                        },
                        {
                          value: "Refuse to take the load and walk off site",
                          label: "Refuse to take the load and walk off site",
                        },
                      ]}
                    />
                  </li>
                  <li>
                    <FormikInput
                      name="documents_while_driving"
                      type="text"
                      label="What documents should you carry when driving?"
                    />
                  </li>
                  <li>
                    <Label>What is a Period of Availability (POA)?</Label>
                    <p>A) Period spent loading and unloading?</p>
                    <p>
                      B) Period of waiting time known in advance, where the
                      driver is free to use the time but is available for work.
                    </p>
                    <p>
                      C) Periods where the driver is being unloaded but must
                      remain in the cab incase the vehicle is requested to move
                    </p>
                    <FormikSelect
                      label="Choose"
                      name="what_is_poa"
                      options={[
                        {
                          value: "Period spent loading and unloading?",
                          label: "A",
                        },
                        {
                          value:
                            "Period of waiting time known in advance, where the driver is free to use the time but is available for work.",
                          label: "B",
                        },
                        {
                          value:
                            "Periods where the driver is being unloaded but must remain in the cab incase the vehicle is requested to move",
                          label: "C",
                        },
                      ]}
                    />
                  </li>
                  <li>
                    <Label>
                      What should you do if you are running out of driving time?
                    </Label>
                    <p>
                      A) Pull your digi card or analogue tacho and carry on
                      driving
                    </p>
                    <p>B) Carry on driving regardless</p>
                    <p>
                      C) Contact the office in advance to let them know of the
                      problem
                    </p>
                    <FormikSelect
                      label="Choose"
                      name="running_out_of_drive_time"
                      options={[
                        {
                          value:
                            "Pull your digi card or analogue tacho and carry on driving",
                          label: "A",
                        },
                        {
                          value: "Carry on Driving regardless",
                          label: "B",
                        },
                        {
                          value:
                            "Contact the office in advance to let them know of the problem",
                          label: "C",
                        },
                      ]}
                    />
                  </li>
                  <li>
                    <FormikSelect
                      label="What should you do at the start of your shift once you have entered your digi card?"
                      name="start_of_shift"
                      options={[
                        {
                          value: "15 minute vehicle check",
                          label: "15 minute vehicle check",
                        },
                        {
                          value: "Load the vehicle",
                          label: "Load the vehicle",
                        },
                        {
                          value: "Drive to first drop",
                          label: "Drive to first drop",
                        },
                      ]}
                    />
                  </li>
                  <li>
                    <FormikInput
                      name="someone_else_drive_tacho_card"
                      type="text"
                      label="Can someone else drive on your digital tachograph card?"
                    />
                  </li>
                </ol>
                <p>
                  <strong>Can you name the symbols below:</strong>
                </p>
                <Col2>
                  <div>
                    <img
                      src={Answer1}
                      alt="Question 1"
                      className="question-image"
                    />
                    <FormikInput
                      name="name_symbol_1"
                      type="text"
                      label="Enter answer"
                    />
                  </div>
                  <div>
                    <img
                      src={Answer2}
                      alt="Question 2"
                      className="question-image"
                    />
                    <FormikInput
                      name="name_symbol_2"
                      type="text"
                      label="Enter answer"
                    />
                  </div>
                  <div>
                    <img
                      src={Answer3}
                      alt="Question 3"
                      className="question-image"
                    />
                    <FormikInput
                      name="name_symbol_3"
                      type="text"
                      label="Enter answer"
                    />
                  </div>
                  <div>
                    <img
                      src={Answer4}
                      alt="Question 4"
                      className="question-image"
                    />
                    <FormikInput
                      name="name_symbol_4"
                      type="text"
                      label="Enter answer"
                    />
                  </div>
                  <div>
                    <img
                      src={Answer5}
                      alt="Question 5"
                      className="question-image"
                    />
                    <FormikInput
                      name="name_symbol_5"
                      type="text"
                      label="Enter answer"
                    />
                  </div>
                </Col2>
              </section>
              <section>
                <h2>Driving Entitlement Consent Form</h2>
                <h3>Notes for guidance</h3>
                <p>Please read the notes before filling in this form.</p>
                <ol>
                  <li>
                    This form should only be filled in to confirm an individual
                    sentitlement to drive. It must be filled in and signed in
                    all cases by the driver. Forms without a signature will not
                    be accepted.
                  </li>
                  <li>
                    If the details on your driving licence are not up to date,
                    return it to us and tell us what changes are needed. It is a
                    legal requirement that you tell us immediately of a change
                    to your permanent address or name. You could be fined up to
                    21000 if you do not notify us of these changes.
                  </li>
                  <li>
                    <p>
                      If you are a bus or lory driver and have passed driver
                      certificate of professional competence (CPC) initial test
                      modules, or completed periodic training since 10 September
                      2008 (forbus drivers), or 10 September 2009 (for lorry
                      drivers) and you need these details included in your
                      response please fill in section 3 with your driver
                      qualification card (DQC) number if known. Please do not
                      fill in section 3 if you do not hold a CPC qualification,
                      The driver CPC information will be sent to the company
                      separately from your driver record details.
                    </p>
                    <p>
                      If you have any queries regarding driver CPC please
                      contact the Driving Standards Agency in the following
                      ways:
                    </p>
                    <p>
                      Phone: <a href="tel:0300 200 1122">0300 200 1122</a>
                    </p>
                    <p>
                      Email:{" "}
                      <a href="mailto:customer.services@dsa.gsi.gov.uk">
                        customer.services@dsa.gsi.gov.uk
                      </a>{" "}
                      (referring to 'Driver CPC Enquiry')
                    </p>
                    <hr />
                  </li>
                  <li>
                    In some instances, the company requesting details of your
                    driver record may be making the request on behalf of the
                    company you have a relationship with.
                  </li>
                  <li>
                    DVLA has a duty under the Data Protection Act 1998 to
                    protect personal information. To ensure adequate protection,
                    DVLA require the specific consent of the driver before
                    releasing information. This information will only be
                    released for the purpose of confirming entitlement to drive
                    and will be held in accordance with the Data Protection Act
                    1998.
                  </li>
                  <li>
                    If you wish to withdraw consent you may do this at anytime
                    under the Data Protection Act 1998. Check with your employer
                    whether they would prefer your withdrawal of consent
                    verbally or in writing. It is your responsibility to obtain
                    acknowledgement of your withdrawal of consent.
                  </li>
                  <li>
                    If you leave your current employer or cease to drive in
                    connection with the named company your consent becomes
                    automatically invalid. If you are re-employed by the same
                    company a new consent form will be required.
                  </li>
                </ol>
                <h3>Warning</h3>
                <p>
                  Failure to provide sufficient information about your company
                  or the driver you are enquiring about, or failure to sign he
                  declaration will result in your application being rejected.
                </p>
                <p>
                  is a criminal offence under •section 55 of the Data Protection
                  Act i998 to unlawfully obtain or disclose (or procure le
                  disclosure 00 personal data from Data Controllers Without the
                  data subject's consent* It is also an offence to ell personal
                  data that is illegally obtained. Convictions for offences are
                  punishable in a Magistrates Court by a fine f up to 25000 or
                  by an unlimited fine in a Crown Court.
                </p>
                <p>
                  Any legitimate complaints received from a driver whose details
                  have been obtained unlawfully may be passed to le Information
                  Commissioner to consider prosecution. If we have evidence that
                  information has been obtained or sed inappropriately we may
                  refuse future applications.
                </p>
              </section>
              <br />
              <Signature
                signatureDrawn={signatureDrawn}
                setSignatureDrawn={setSignatureDrawn}
                sigCanvas={sigCanvas}
              />
              {errorText
                ? errorText.map(e => (
                  <Error style={{ marginTop: "1rem" }} key={e}>
                    {e}
                  </Error>
                ))
                : null}
              <SButton
                type="submit"
                //disabled={isSubmitting}
                onClick={() => {
                  isSubmitting ? console.log(errors) : null
                }}
              >
                Submit
              </SButton>
            </Form>
          )}
        </Formik>
      </LCard>
    </Layout>
  )
}

export default HealthcareApplication
